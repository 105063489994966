export default defineNuxtRouteMiddleware( () => {
  const user = useSupabaseUser();

  if ( !user.value || !user.value.app_metadata.app_admin ) {
    return abortNavigation( {
      statusCode: 401,
      message: "You must be logged in as an admin to view this page",
    } );
  }
} );
